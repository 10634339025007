.custom-control.checkbox {
    .custom-control-input {
        &:hover {
            cursor: pointer;
        }
    }
    .custom-control-label {
        &::before {
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        }
        &::after {
            border: 1px solid #adb5bd;
        }
    }
}