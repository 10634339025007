/*
 * Sidebar
 */

 .sidebar {
  font-size: 0.9em;
  font-family: 'NeueMachina';
  font-weight: 400;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: $navbar-height 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - #{$navbar-height});
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #333;
  font-weight: bold;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar .nav-link:hover {
  color: #333;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

#logout_sidebar_button {
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 15px;
}
