body {
    font-weight: 400;
}
svg#definition {
  display: none;
}

.visually-hidden {
  border: 0!important;
  clip: rect(0 0 0 0)!important;
  height: 1px!important;
  margin: -1px!important;
  overflow: hidden!important;
  padding: 0!important;
  position: absolute!important;
  width: 1px!important;
}

h1, h2 {
  font-family: 'NeueMachina';
  font-weight: 400;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
