.full-height {
    height: 100vh;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

.content {
    text-align: center;
}

.title {
    font-size: 7vw;
    line-height: 1.1em;
}

.links > a {
    color: #636b6f;
    padding: 0 25px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
}

.m-b-md {
    margin-bottom: 30px;
}

.logo-main {
  max-width: 90%;
  width: 512px;
  height: auto;
  display: inline-block;
}

.logo-login {
  max-width: 90%;
  width: 256px;
  height: auto;
  display: inline-block;
}
