.custom-file-container__image-multi-preview, .custom-file-container__image-preview {
  cursor: pointer;
}
.custom-file-container__image-preview {
  margin-bottom: 0;
}
[data-upload-id="avatarupload"] .custom-file-container__image-preview {
  width: 250px;
  height: 250px;
}

.custom-file-container__image-multi-preview {
  width: 133px;
  height: 133px;
}

.custom-file-container__image-multi-preview__single-file-name {
  position: absolute;
  bottom: -21px;
  font-size: 9px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
