@import '../components/sidebar';
@import '../components/navbar';

/*
 * Content
 */

[role="main"] {
  padding-top: $navbar-height; /* Space for fixed navbar */
}
