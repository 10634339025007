.search-cancel {
  top: 0;
  right: 0;
  z-index: 100;
  padding: 5px 5px;
  line-height: 0;
  position: absolute;
  color: #999;
  &:hover {
    color: #333;
  }
}
