
/*
 * Navbar
 */

.navbar {
  font-family: 'NeueMachina';
}

.navbar-brand {
  // padding-top: .75rem;
  // padding-bottom: .75rem;
  font-size: 1rem;
  // box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-white {
  color: #333;
  background-color: rgba(0, 0, 0, .1);
  border-color: rgba(0, 0, 0, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.form-control-white:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, .25);
}

.logo-navbar {
  max-height: 100%;
  height: 30px;
  width: auto;
  display: inline-block;
}

.dropdown-item.active {
  background-color: transparent;
  font-weight: bold;
  color: #333;
}
