.form-control {
  height: auto !important;
}

.table > thead > tr > th {
    border: none;
}

.btn {
  font-family: 'NeueMachina';
}
