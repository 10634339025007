.icon {
  width: 0.5em;
  height: 0.5em;
  fill: red;
  vertical-align: top;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  left: -10000px;
  overflow: hidden;
}
