// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@font-face {
    font-family: 'NeueMachina';
    font-weight: 400;
    src: url('../fonts/NeueMachina-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'NeueMachina';
    font-weight: 500;
    src: url('../fonts/NeueMachina-Ultrabold.woff2') format('woff2');
}

// Variables
@import 'variables';

@import '~bootstrap/scss/bootstrap';
@import '~@yaireo/tagify/src/tagify';
@import '~file-upload-with-preview/src/file-upload-with-preview';
@import '~easymde/dist/easymde.min.css';

@import 'bootstrap';
@import 'globals';

@import 'components/requiredicon';
@import 'components/simplemde';
@import 'components/fileuploadwithpreview';

@import 'layouts/dashboard';
@import 'layouts/admin-home';
@import 'components/login';
@import 'components/searchbar';
@import 'components/editactions';
@import 'components/checkbox';
